#sectionBrand {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  .sectionOneTextContentContainer {
    overflow: hidden;
    span {
      color: #a5763b;
      font-weight: bold;
    }
  }
  .sectionImgContentContainer {
    overflow: hidden;
    position: relative;
    span {
      color: #a5763b;
      font-weight: bold;
    }
    .parallax-outer {
      position: relative;
      top: 0px;
    }
  }
  .sub-section-img {
    position: absolute;
    left: 0;
    top: 0;
  }
  #subSectionOneImgOne {
    top: 0px;
    z-index: 1;
    @media (max-width: 600px) {
      margin-top: 0px;
      left: 60px;
    }
  }
  #subSectionOneImgTwo {
    top: 80px;
    z-index: 2;
    left: 43%;
    @media (max-width: 600px) {
      display: none;
    }
  }
  #subSectionOneImgThree {
    margin-top: -20px;
    z-index: 2;
    left: 41%;
    width: 130px;
    @media (max-width: 600px) {
      display: none;
    }
  }
  #subSectionOneImgFour {
    position: relative;
    z-index: 2;
    margin-top: 250px;
    margin-bottom: 40px;
    left: -25%;
    @media (max-width: 600px) {
      margin-top: 120px;
      left: 0px;
    }
  }
}
