.headerGrid {
  min-height: 70px;
  position: fixed;
  box-shadow: 0px 0px 30px #00000024;
  background: #fff;
  z-index: 6;
  margin-top: 30px;
  .drawer-button {
    min-width: auto;
    vertical-align: text-bottom;
    top: 0;
    padding-right: 30px;
    .drawer-button-icon {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.8rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
    }
  }
}

.drawer-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.8rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.drawer-close {
  position: absolute;
  right: 0px;
  margin: 2rem;
  font-size: 1.5rem;
  color: #333;
}

.drawer-copyright {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  color: #333;
}

.drawerListContainer {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: right;
  ul {
    padding: 0 3rem;
    li {
      list-style-type: none;
      margin: 1rem 0;
      button {
        font-size: 1.5rem;
        font-family: Prata;
        color: #000;
        text-decoration: none;
        background: none;
        border: none;
        &:hover {
          color: #333;
          cursor: hand;
          cursor: pointer;
        }
      }
    }
  }
}

.scrolling-text {
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  top: 0px;
  width: 100%;
  background-color: #03ff66;
  z-index: 6;
  box-sizing: border-box;
  position: fixed;
  display: block;
  &:Hover{
    cursor: pointer;
  }
  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    animation: marquee 100s linear infinite;
    padding-left: 0%;
    display: inline-block;
    margin: 0;
  }
}
@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}

#trustpilotLogo{
  width: 100px;
}