@import url(https://use.typekit.net/wwk0qkn.css);
@import url(https://fonts.googleapis.com/css2?family=Prata&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.headerGrid {
  min-height: 70px;
  position: fixed;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1411764706);
  background: #fff;
  z-index: 6;
  margin-top: 30px;
}
.headerGrid .drawer-button {
  min-width: auto;
  vertical-align: text-bottom;
  top: 0;
  padding-right: 30px;
}
.headerGrid .drawer-button .drawer-button-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.8rem;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.drawer-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.8rem;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.drawer-close {
  position: absolute;
  right: 0px;
  margin: 2rem;
  font-size: 1.5rem;
  color: #333;
}

.drawer-copyright {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  color: #333;
}

.drawerListContainer {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: right;
}
.drawerListContainer ul {
  padding: 0 3rem;
}
.drawerListContainer ul li {
  list-style-type: none;
  margin: 1rem 0;
}
.drawerListContainer ul li button {
  font-size: 1.5rem;
  font-family: Prata;
  color: #000;
  text-decoration: none;
  background: none;
  border: none;
}
.drawerListContainer ul li button:hover {
  color: #333;
  cursor: hand;
  cursor: pointer;
}

.scrolling-text {
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  top: 0px;
  width: 100%;
  background-color: #03ff66;
  z-index: 6;
  box-sizing: border-box;
  position: fixed;
  display: block;
}
.scrolling-text:Hover {
  cursor: pointer;
}
.scrolling-text p {
  font-size: 0.8rem;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  -webkit-animation: marquee 100s linear infinite;
          animation: marquee 100s linear infinite;
  padding-left: 0%;
  display: inline-block;
  margin: 0;
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}
#trustpilotLogo {
  width: 100px;
}
#ecodeaLogo {
  max-width: 9rem;
  width: 100%;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media screen and (max-width: 960px) {
  #ecodeaLogo {
    left: 25px;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
#footerContainer {
  width: 80%;
  background: #000;
  overflow: auto;
  padding: 0 10%;
}
#footerContainer p {
  color: #fff;
}

#footerSeperator {
  background-color: #03ff66;
  width: 100%;
  height: 1px;
  position: relative;
  display: block;
  margin: 1rem 0;
}

#footerLogoContainer {
  width: 100%;
  margin-top: 20px;
  margin-bottom: -15px;
}
#footerLogoContainer #ecodeaLogoFooter {
  max-width: 6rem;
  width: 100%;
  position: relative;
  margin-right: 100%;
  margin-top: 1rem;
}
@media screen and (max-width: 960px) {
  #footerLogoContainer #ecodeaLogoFooter {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@media screen and (max-width: 960px) {
  #footerLogoTagline {
    text-align: center;
    width: 100%;
  }
}

.termsLink {
  float: right;
  margin-top: 0;
}

.privacyLink {
  float: right;
  padding: 0 2rem;
  margin-top: 0;
}

.ecodeaCopyright {
  float: left;
  margin-top: 0;
}

#footerLinks {
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding-top: 20px;
}
#footerLinks a {
  color: #fff;
  text-decoration: none;
  background: none;
  border: none;
}
#footerLinks span {
  padding: 0 1rem;
  color: #fff;
}
#sectionBrand {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
#sectionBrand .sectionOneTextContentContainer {
  overflow: hidden;
}
#sectionBrand .sectionOneTextContentContainer span {
  color: #a5763b;
  font-weight: bold;
}
#sectionBrand .sectionImgContentContainer {
  overflow: hidden;
  position: relative;
}
#sectionBrand .sectionImgContentContainer span {
  color: #a5763b;
  font-weight: bold;
}
#sectionBrand .sectionImgContentContainer .parallax-outer {
  position: relative;
  top: 0px;
}
#sectionBrand .sub-section-img {
  position: absolute;
  left: 0;
  top: 0;
}
#sectionBrand #subSectionOneImgOne {
  top: 0px;
  z-index: 1;
}
@media (max-width: 600px) {
  #sectionBrand #subSectionOneImgOne {
    margin-top: 0px;
    left: 60px;
  }
}
#sectionBrand #subSectionOneImgTwo {
  top: 80px;
  z-index: 2;
  left: 43%;
}
@media (max-width: 600px) {
  #sectionBrand #subSectionOneImgTwo {
    display: none;
  }
}
#sectionBrand #subSectionOneImgThree {
  margin-top: -20px;
  z-index: 2;
  left: 41%;
  width: 130px;
}
@media (max-width: 600px) {
  #sectionBrand #subSectionOneImgThree {
    display: none;
  }
}
#sectionBrand #subSectionOneImgFour {
  position: relative;
  z-index: 2;
  margin-top: 250px;
  margin-bottom: 40px;
  left: -25%;
}
@media (max-width: 600px) {
  #sectionBrand #subSectionOneImgFour {
    margin-top: 120px;
    left: 0px;
  }
}
#sectionExpertise {
  position: relative;
}
@media (max-width: 960px) {
  #sectionExpertise {
    overflow: hidden;
  }
}
#sectionExpertise .sectionExpertiseTextContentContainer {
  overflow: hidden;
}
#sectionExpertise .sectionExpertiseTextContentContainer span {
  color: #a5763b;
  font-weight: bold;
}
#sectionExpertise .sectionExpertiseTextContentContainer p {
  margin-top: 1.5rem;
}
#sectionExpertise #partnerList {
  margin-top: 1rem;
  z-index: 0;
  position: relative;
}
@media (max-width: 600px) {
  #sectionExpertise #partnerList .clientImage {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
#sectionExpertise #partnerList .MuiGrid-item {
  padding-right: 1rem !important;
  padding-left: 0 !important;
  margin: 1rem 0 !important;
}
#sectionExpertise #partnerList .MuiGrid-item img {
  max-width: 100px;
}
#sectionExpertise #sectionBoxOne {
  background-color: #9cb29e;
  display: block;
  width: 55%;
  height: 260px;
  position: absolute;
  left: -40px;
  top: -10%;
  z-index: -1;
}
#sectionExpertise #sectionBoxTwo {
  background-color: #1b3644;
  display: block;
  width: 200px;
  height: 127px;
  position: absolute;
  right: 100px;
  top: 80px;
  z-index: -1;
}
#sectionExpertise #sectionBoxThree {
  background-color: #ccbba5;
  display: block;
  width: 60%;
  height: 150px;
  position: absolute;
  left: 270px;
  top: 505px;
  z-index: -1;
}
#sectionExpertise #sectionBoxFour {
  z-index: 1;
  padding: 2rem;
  display: block;
  max-width: 405px;
  position: relative;
  box-shadow: 0px 0px 30px -10px #000;
  background: #1b3340;
  background: linear-gradient(167deg, #1b3340 0%, #1b3644 28%, #1b3644 100%);
  margin: 0 auto;
}
#sectionExpertise #sectionExpertiseContent {
  color: #fff;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding-bottom: 100px;
}
#sectionExpertise #sectionExpertiseContent h5 {
  margin-top: 20px !important;
}
#sectionExpertise #sectionExpertiseContent h2 {
  color: #fff;
}
#sectionExpertise #sectionExpertiseContent .expertiseContentList {
  margin: 0;
  padding: 0 20px;
}

.clientHeading {
  color: #000;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  font-family: Open Sans, Arial, sans-serif;
}

.content {
  overflow: hidden;
  position: relative;
  font-family: "Lato", sans-serif;
  font-size: 1.8rem;
  line-height: 40px;
  color: #ecf0f1;
  margin-bottom: 30px;
  margin-top: 20px;
}
@media (max-width: 600px) {
  .content {
    font-size: 1.2rem;
  }
}
@media (max-width: 340px) {
  .content {
    font-size: 1rem;
  }
}
.content__container {
  font-weight: 600;
  overflow: hidden;
  height: 40px;
}
.content__container__text {
  display: inline;
  float: left;
  margin: 0;
  position: absolute;
}
.content__container__list {
  margin-top: 0;
  padding-left: 240px;
  text-align: left;
  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}
@media (max-width: 600px) {
  .content__container__list {
    padding-left: 162px;
  }
}
@media (max-width: 340px) {
  .content__container__list {
    padding-left: 140px;
  }
}
.content__container__list__item {
  line-height: 40px;
  margin: 0;
}

@-webkit-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes change {
  0%, 12.66%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  16.66%, 29.32% {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
  33.32%, 45.98% {
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
  }
  49.98%, 62.64% {
    -webkit-transform: translate3d(0, -75%, 0);
            transform: translate3d(0, -75%, 0);
  }
  66.64%, 79.3% {
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
  }
  83.3%, 95.96% {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
}
@keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes change {
  0%, 12.66%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  16.66%, 29.32% {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
  33.32%, 45.98% {
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
  }
  49.98%, 62.64% {
    -webkit-transform: translate3d(0, -75%, 0);
            transform: translate3d(0, -75%, 0);
  }
  66.64%, 79.3% {
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0);
  }
  83.3%, 95.96% {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
}
#sectionBoxFour h3 {
  display: inline-block;
  color: white;
}
#sectionDownload {
  position: relative;
  overflow: hidden;
}
#sectionDownload .download-header {
  width: 100%;
  font-weight: bold;
}
#sectionDownload .download-body {
  text-align: justify;
  margin-bottom: 1rem;
}
#sectionDownload .download-button {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
#sectionDownload .sectionImgContentContainer {
  overflow: hidden;
  position: relative;
  height: 600px;
}
#sectionDownload .sectionImgContentContainer span {
  color: #a5763b;
  font-weight: bold;
}
#sectionDownload .sectionImgContentContainer .parallax-outer {
  position: relative;
  top: 0px;
}
#sectionDownload #downloadImgOne {
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-53%);
          transform: translateX(-53%);
}
@media (max-width: 600px) {
  #sectionDownload #downloadImgOne {
    left: 0;
    -webkit-transform: none;
            transform: none;
  }
}
#sectionDownload #downloadImgTwo {
  position: relative;
  margin-bottom: 240px;
  width: 260px;
  top: 215px;
  left: 20%;
}
@media (max-width: 600px) {
  #sectionDownload #downloadImgTwo {
    left: 10%;
  }
}
#sectionDownload #catalogueBusinessForm {
  max-width: 400px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-top: 2rem;
  margin-bottom: 2rem;
}
#sectionDownload #businessCatalogueTextfield {
  float: left;
}
#sectionDownload #catalogueFormContainer {
  max-width: 500px;
  width: 80%;
}
#sectionDownload #catalogueSubmitButton {
  display: block;
  position: relative;
  left: 70px;
  border-radius: 50%;
  border: none;
  background-color: #000;
  color: #fff;
  /* padding: 15px; */
  height: 40px;
  width: 40px;
  margin-top: 10px;
  outline: none;
}

.successAlertColor {
  background-color: #03ff66 !important;
}

#downloadSuccessAlert {
  background-color: #03ff66;
  color: #000;
  -webkit-transition: 1s opacity;
  transition: 1s opacity;
  opacity: 1;
}

.alertShow {
  opacity: 0 !important;
}
.MuiDialog-paperWidthSm {
  background-color: #fff;
  border-radius: 0px;
}

#textArea {
  margin-top: 1rem;
}
#formDialogue #ecodeaLogo {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 3rem 0rem 4rem 0;
}

.dialog-title {
  text-align: center;
  width: 100%;
  display: block;
}

.MuiDialogActions-root {
  padding: 22px !important;
}
@media (max-width: 960px) {
  #sectionForm {
    overflow: hidden;
  }
}

#newsButton {
  padding: 15px 80px;
  border-radius: 0px;
  font-size: 1.2rem;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 57px;
  margin-right: 20px;
  margin-top: 2rem;
  white-space: nowrap;
}
#newsButton:after {
  content: "";
  display: block;
  background-color: #9cb29e;
  width: 100%;
  height: 100%;
  position: absolute;
  left: -20px;
  top: 20px;
  z-index: -1;
}

#quoteButton {
  padding: 15px 80px;
  border-radius: 0px;
  font-size: 1.2rem;
  text-transform: capitalize;
  color: #fff;
  margin-top: 2rem;
  white-space: nowrap;
}
#quoteButton:after {
  content: "";
  display: block;
  background-color: #9cb29e;
  width: 100%;
  height: 100%;
  position: absolute;
  right: -60px;
  top: -30px;
  z-index: -1;
}

#instagramHandle {
  width: 100%;
  text-align: center;
  margin: 2rem 0;
  font-size: 18px;
}
#instagramHandle:hover {
  cursor: pointer;
  cursor: hand;
}

.instagramGallery {
  display: grid;
  grid-template-columns: repeat(3);
  grid-template-rows: repeat(2);
  grid-gap: 15px;
  margin: 0 auto;
}
.instagramGallery .instagram-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  box-shadow: 0px 0px 50px -20px #484848;
}
.instagramGallery .gallery__item {
  margin: 0;
}
.instagramGallery .gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  /** Alternative Syntax **/
  /* grid-column: 1 / span 2;  */
  /* grid-row: 1 / span 2; */
}
.instagramGallery .gallery__item--2 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  /** Alternative Syntax **/
  /* grid-column: 3 / span 2;  */
  /* grid-row: 1 / span 2; */
}
.instagramGallery .gallery__item--3 {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  /** Alternative Syntax **/
  /* grid-column: 5 / span 4;
  grid-row: 1 / span 5; */
}
.instagramGallery .gallery__item--4 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
  /** Alternative Syntax **/
  /* grid-column: 1 / span 4;  */
  /* grid-row: 3 / span 3; */
}
.instagramGallery .gallery__item--5 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  /** Alternative Syntax **/
  /* grid-column: 1 / span 4; */
  /* grid-row: 6 / span 3; */
}
.instagramGallery .gallery__item--6 {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
  /** Alternative Syntax **/
  /* grid-column: 5 / span 4; */
  /* grid-row: 6 / span 3; */
}

#instagramImageGridItem {
  z-index: 1;
}

.blinking-cursor {
  -webkit-animation: 1.5s blink step-end infinite;
  animation: 1.5s blink step-end infinite;
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
@-webkit-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
#sectionsContainer {
  overflow: auto;
}
#sectionsContainer .MuiGrid-item {
  padding: 0 2rem;
}
@media (max-width: 960px) {
  #sectionsContainer .MuiGrid-item {
    padding: 0 0.5rem;
  }
}
@media (max-width: 960px) {
  #sectionsContainer .MuiGrid-item {
    margin: 3rem 0 1rem 0 !important;
  }
  #sectionsContainer .MuiGrid-item:nth-child(2) {
    margin: 1rem 0 3rem 0 !important;
  }
}
#sectionsContainer #sectionExpertise > .MuiGrid-container > .MuiGrid-item {
  padding: 0 2rem;
}
@media (max-width: 960px) {
  #sectionsContainer #sectionExpertise > .MuiGrid-container > .MuiGrid-item {
    margin: 1rem 0 3rem 0 !important;
  }
  #sectionsContainer #sectionExpertise > .MuiGrid-container > .MuiGrid-item:nth-child(2) {
    margin: 3rem 0 1rem 0 !important;
  }
}
@media (min-width: 960px) {
  #sectionsContainer .section {
    margin: 10rem auto !important;
  }
}
.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
  top: 90%;
}

.icon-scroll {
  width: 30px;
  height: 50px;
  margin-left: -20px;
  bottom: 10px;
  margin-top: -35px;
  border: 2px solid #fff;
  border-radius: 25px;
}

@media (max-width: 767px) {
  .icon-scroll {
    display: none;
  }
}
.icon-scroll:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
          animation-name: scroll;
}

@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(26px);
            transform: translateY(26px);
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(26px);
            transform: translateY(26px);
  }
}
#introWrapper {
  position: relative;
  background-color: #f3f3f3;
  padding-top: 100px;
  overflow: hidden;
}
@media (min-width: 767px) {
  #introWrapper {
    padding-top: 0px;
  }
}
#introWrapper h1 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

#introVideo {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0px;
}

#header {
  position: absolute;
  color: #fff;
  top: calc(50% - 50px);
  font-size: 5rem !important;
  z-index: 1;
  text-align: center;
  width: 100%;
}
#header span {
  color: transparent;
  animation: blur 10s ease-out;
  -webkit-animation: blur 10s ease-out;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}
#header span:nth-child(1) {
  animation-delay: 0.1s;
  -webkit-animation-delay: 0.1s;
}
#header span:nth-child(2) {
  animation-delay: 0.2s;
  -webkit-animation-delay: 0.2s;
}
#header span:nth-child(3) {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}

#header {
  color: #fff;
}

@keyframes blur {
  0% {
    text-shadow: 0 0 100px #fff;
    opacity: 0;
  }
  5% {
    text-shadow: 0 0 90px #fff;
  }
  15% {
    opacity: 1;
  }
  20% {
    text-shadow: 0 0 0px #fff;
  }
  80% {
    text-shadow: 0 0 0px #fff;
  }
  100% {
    opacity: 1;
    color: #fff;
  }
}
@-webkit-keyframes blur {
  0% {
    text-shadow: 0 0 100px #fff;
    opacity: 0;
  }
  5% {
    text-shadow: 0 0 90px #fff;
  }
  15% {
    opacity: 1;
  }
  20% {
    text-shadow: 0 0 0px #fff;
  }
  80% {
    text-shadow: 0 0 0px #fff;
  }
  100% {
    opacity: 1;
    color: #fff;
  }
}
html,
body,
.App,
#root {
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

.box {
  background: #fff;
  width: 5rem;
  height: 5rem;
}

#contentWrapper {
  position: relative;
  top: 10vh;
  display: block;
  width: 100%;
}

#trustpilotLogo {
  width: 100px;
}
