#sectionExpertise {
  position: relative;
  @media (max-width: 960px) {
    overflow: hidden;
  }
  .sectionExpertiseTextContentContainer {
    overflow: hidden;
    span {
      color: #a5763b;
      font-weight: bold;
    }
    p {
      margin-top: 1.5rem;
    }
  }

  #partnerList {
    margin-top: 1rem;
    z-index: 0;
    position: relative;
    .clientImage{
      @media (max-width: 600px) {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  #partnerList .MuiGrid-item {
    padding-right: 1rem !important;
    padding-left: 0 !important;
    margin: 1rem 0 !important;
    img {
      max-width: 100px;
    }
  }

  #sectionBoxOne {
    background-color: #9cb29e;
    display: block;
    width: 55%;
    height: 260px;
    position: absolute;
    left: -40px;
    top: -10%;
    z-index: -1;
  }
  #sectionBoxTwo {
    background-color: #1b3644;
    display: block;
    width: 200px;
    height: 127px;
    position: absolute;
    right: 100px;
    top: 80px;
    z-index: -1;
  }
  #sectionBoxThree {
    background-color: #ccbba5;
    display: block;
    width: 60%;
    height: 150px;
    position: absolute;
    left: 270px;
    top: 505px;
    z-index: -1;
  }
  #sectionBoxFour {
    z-index: 1;
    padding: 2rem;
    display: block;
    max-width: 405px;
    position: relative;
    box-shadow: 0px 0px 30px -10px #000;
    background: #1b3340;
    background: linear-gradient(167deg, #1b3340 0%, #1b3644 28%, #1b3644 100%);
    margin: 0 auto;
  }
  #sectionExpertiseContent {
    color: #fff;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 100px;

    h5 {
      margin-top: 20px !important;
    }
    h2 {
      color: #fff;
    }
    .expertiseContentList {
      margin: 0;
      padding: 0 20px;
    }
  }
}

.clientHeading{
  color: #000;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  font-family: Open Sans, Arial, sans-serif;
}

.content {
  overflow: hidden;
  position: relative;
  font-family: "Lato", sans-serif;
  font-size: 1.8rem;
  line-height: 40px;
  color: #ecf0f1;
  margin-bottom: 30px;
  margin-top: 20px;
  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
  @media (max-width: 340px) {
    font-size: 1rem;
  }

  &__container {
    font-weight: 600;
    overflow: hidden;
    height: 40px;

    &__text {
      display: inline;
      float: left;
      margin: 0;
      position: absolute;
    }

    &__list {
      margin-top: 0;
      padding-left: 240px;
      text-align: left;
      list-style: none;

      -webkit-animation-name: change;
      -webkit-animation-duration: 10s;
      -webkit-animation-iteration-count: infinite;
      animation-name: change;
      animation-duration: 10s;
      animation-iteration-count: infinite;

      @media (max-width: 600px) {
        padding-left: 162px;
      }
      @media (max-width: 340px) {
        padding-left: 140px;
      }

      &__item {
        line-height: 40px;
        margin: 0;
      }
    }
  }
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-o-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-moz-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

#sectionBoxFour h3 {
  display: inline-block;
  color: white;
}
