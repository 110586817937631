#formDialogue #ecodeaLogo {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 3rem 0rem 4rem 0;
}

.dialog-title {
  text-align: center;
  width: 100%;
  display: block;
}

.MuiDialogActions-root {
  padding: 22px !important;
}

