#sectionsContainer {
  overflow: auto;
  .MuiGrid-item {
    padding: 0 2rem;
    @media (max-width: 960px) {
      padding: 0 0.5rem;
    }
    @media (max-width: 960px) {
      margin: 3rem 0 1rem 0 !important;
      &:nth-child(2) {
        margin: 1rem 0 3rem 0 !important;
      }
    }
  }
  #sectionExpertise > .MuiGrid-container > .MuiGrid-item {
    padding: 0 2rem;
    @media (max-width: 960px) {
      margin: 1rem 0 3rem 0 !important;
      &:nth-child(2) {
        margin: 3rem 0 1rem 0 !important;
      }
    }
  }
  .section {
    @media (min-width: 960px) {
      margin: 10rem auto !important;
    }
  }
}
