.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
  top: 90%;
}
.icon-scroll {
  width: 30px;
  height: 50px;
  margin-left: -20px;
  bottom: 10px;
  margin-top: -35px;
  border: 2px solid #fff;
  border-radius: 25px;
}
@media (max-width: 767px) {
  .icon-scroll {
    display: none;
  }
}
.icon-scroll:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(26px);
  }
}

#introWrapper {
  position: relative;
  background-color: #f3f3f3;
  padding-top: 100px;
  overflow: hidden;
  @media (min-width: 767px) {
    padding-top: 0px;
  }
  h1 {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
  }
}

#introVideo {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0px;
}

#header {
  position: absolute;
  color: #fff;
  top: calc(50% - 50px);
  font-size: 5rem !important;
  z-index: 1;
  text-align: center;
  width: 100%;
  span {
    color: transparent;
    animation: blur 10s ease-out;
    -webkit-animation: blur 10s ease-out;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
  span:nth-child(1) {
    animation-delay: 0.1s;
    -webkit-animation-delay: 0.1s;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
  }
}

#header {
  color: #fff;
}

@keyframes blur {
  0% {
    text-shadow: 0 0 100px #fff;
    opacity: 0;
  }
  5% {
    text-shadow: 0 0 90px #fff;
  }
  15% {
    opacity: 1;
  }
  20% {
    text-shadow: 0 0 0px #fff;
  }
  80% {
    text-shadow: 0 0 0px #fff;
  }
  100% {
    opacity: 1;
    color: #fff;
  }
}

@-webkit-keyframes blur {
  0% {
    text-shadow: 0 0 100px #fff;
    opacity: 0;
  }
  5% {
    text-shadow: 0 0 90px #fff;
  }
  15% {
    opacity: 1;
  }
  20% {
    text-shadow: 0 0 0px #fff;
  }
  80% {
    text-shadow: 0 0 0px #fff;
  }
  100% {
    opacity: 1;
    color: #fff;
  }
}
