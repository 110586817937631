#footerContainer {
  width: 80%;
  background: #000;
  overflow: auto;
  padding: 0 10%;
  p {
    color: #fff;
  }
}

#footerSeperator {
  background-color: #03ff66;
  width: 100%;
  height: 1px;
  position: relative;
  display: block;
  margin: 1rem 0;
}

#footerLogoContainer {
  width: 100%;
  margin-top: 20px;
  margin-bottom: -15px;
  #ecodeaLogoFooter {
    max-width: 6rem;
    width: 100%;
    position: relative;
    margin-right: 100%;
    margin-top: 1rem;
    @media screen and (max-width: 960px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

#footerLogoTagline{
  @media screen and (max-width: 960px){
    text-align: center;
    width: 100%;
  }  
}

.termsLink {
  float: right;
  margin-top: 0;
}

.privacyLink {
  float: right;
  padding: 0 2rem;
  margin-top: 0;
}

.ecodeaCopyright {
  float: left;
  margin-top: 0;
}

#footerLinks {
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding-top: 20px;
  a {
    color: #fff;
    text-decoration: none;
    background: none;
    border: none;
  }
  span {
    padding: 0 1rem;
    color: #fff;
  }
}
