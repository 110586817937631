#sectionForm {
  @media (max-width: 960px) {
    overflow: hidden;
  }
}

#newsButton {
  padding: 15px 80px;
  border-radius: 0px;
  font-size: 1.2rem;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 57px;
  margin-right: 20px;
  margin-top: 2rem;
  white-space: nowrap;
  &:after {
    content: "";
    display: block;
    background-color: #9cb29e;
    width: 100%;
    height: 100%;
    position: absolute;
    left: -20px;
    top: 20px;
    z-index: -1;
  }
}

#quoteButton {
  padding: 15px 80px;
  border-radius: 0px;
  font-size: 1.2rem;
  text-transform: capitalize;
  color: #fff;
  margin-top: 2rem;
  white-space: nowrap;
  &:after {
    content: "";
    display: block;
    background-color: #9cb29e;
    width: 100%;
    height: 100%;
    position: absolute;
    right: -60px;
    top: -30px;
    z-index: -1;
  }
}

#instagramHandle {
  width: 100%;
  text-align: center;
  margin: 2rem 0;
  font-size: 18px;
  &:hover {
    cursor: pointer;
    cursor: hand;
  }
}

.instagramGallery {
  display: grid;
  grid-template-columns: repeat(3);
  grid-template-rows: repeat(2);
  grid-gap: 15px;

  margin: 0 auto;
  .instagram-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    box-shadow: 0px 0px 50px -20px #484848;
  }
  .gallery__item {
    margin: 0;
  }
  .gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;

    /** Alternative Syntax **/
    /* grid-column: 1 / span 2;  */
    /* grid-row: 1 / span 2; */
  }

  .gallery__item--2 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;

    /** Alternative Syntax **/
    /* grid-column: 3 / span 2;  */
    /* grid-row: 1 / span 2; */
  }

  .gallery__item--3 {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;

    /** Alternative Syntax **/
    /* grid-column: 5 / span 4;
    grid-row: 1 / span 5; */
  }

  .gallery__item--4 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;

    /** Alternative Syntax **/
    /* grid-column: 1 / span 4;  */
    /* grid-row: 3 / span 3; */
  }

  .gallery__item--5 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;

    /** Alternative Syntax **/
    /* grid-column: 1 / span 4; */
    /* grid-row: 6 / span 3; */
  }

  .gallery__item--6 {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 2;

    /** Alternative Syntax **/
    /* grid-column: 5 / span 4; */
    /* grid-row: 6 / span 3; */
  }
}

#instagramImageGridItem {
  z-index: 1;
}

.blinking-cursor {
  -webkit-animation: 1.5s blink step-end infinite;
  -moz-animation: 1.5s blink step-end infinite;
  -ms-animation: 1.5s blink step-end infinite;
  -o-animation: 1.5s blink step-end infinite;
  animation: 1.5s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
