#sectionDownload {
  position: relative;
  overflow: hidden;
  .download-header {
    width: 100%;
    font-weight: bold;
  }
  .download-body {
    text-align: justify;
    margin-bottom: 1rem;
  }
  .download-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .sectionImgContentContainer {
    overflow: hidden;
    position: relative;
    height: 600px;
    span {
      color: #a5763b;
      font-weight: bold;
    }
    .parallax-outer {
      position: relative;
      top: 0px;
    }
  }
  #downloadImgOne {
    left: 50%;
    position: absolute;
    transform: translateX(-53%);
    @media (max-width: 600px) {
      left: 0;
      transform: none;
    }
  }
  #downloadImgTwo {
    position: relative;
    margin-bottom: 240px;
    width: 260px;
    top: 215px;
    left: 20%;
    @media (max-width: 600px) {
      left: 10%;
    }
  }
  #catalogueBusinessForm {
    max-width: 400px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  #businessCatalogueTextfield {
    float: left;
  }

  #catalogueFormContainer {
    max-width: 500px;
    width: 80%;
  }

  #catalogueSubmitButton {
    display: block;
    position: relative;
    left: 70px;
    border-radius: 50%;
    border: none;
    background-color: #000;
    color: #fff;
    /* padding: 15px; */
    height: 40px;
    width: 40px;
    margin-top: 10px;
    outline: none;
  }
}

.successAlertColor {
  background-color: #03ff66 !important;
}

#downloadSuccessAlert {
  background-color: #03ff66;
  color: #000;
  transition: 1s opacity;
  opacity: 1;
}

.alertShow {
  opacity: 0 !important;
}
