#ecodeaLogo {
  max-width: 9rem;
  width: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 960px) {
    left: 25px;
    transform: translateX(0);
  }
}
