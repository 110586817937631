@import url("https://use.typekit.net/wwk0qkn.css");
@import url("https://fonts.googleapis.com/css2?family=Prata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

html,
body,
.App,
#root {
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

.box {
  background: #fff;
  width: 5rem;
  height: 5rem;
}
#contentWrapper {
  position: relative;
  top: 10vh;
  display: block;
  width: 100%;
}

#trustpilotLogo{
  width: 100px;
}